<template>
  <b-card class="col-12 col-lg-6 c">
    <div class="col-12 text-center" v-if="loading">
      <img
        src="../../assets/images/loading.svg"
        style="width: 120px; height: 120px; margin: 0px auto"
      />
      <br />
      جاري الاستيراد...
    </div>
    <div ref="ff">
      <div class="form-group">
        <label for=""><h4>قم بإرفاق ملف المعلمين من نور</h4></label>
        <br /><br />
        <input
          type="file"
          class="form-control-file"
          ref="file"
          accept=".xls,.xlsx"
          v-on:change="handleFileUpload($event)"
        />
        <br />
        <small> بعد اختيار الملف، سوف يتم الإستيراد مباشرة. </small>
      </div>
    </div>
  </b-card>
</template>

<script>
import { BCard, BCardText } from "bootstrap-vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
const axios = require("axios");
export default {
  components: {
    BCard,
    BCardText,
  },
  data() {
    return {
      loading: false,
    };
  },
  methods: {
    handleFileUpload(event) {
      var _g = this;
      this.fileUploaded = event.target.files[0];
      var fd = new FormData();
      fd.append("jwt", localStorage.getItem("jwt"));
      fd.append("file", this.fileUploaded);
      _g.loading = true;
      _g.$refs.ff.innerHTML = "";
      axios({
        method: "post",
        url: localStorage.getItem("api2") + "/teachers/import",
        data: fd,
        headers: { "Content-Type": "multipart/form-data" },
      })
        .then(function (response) {
          _g.$toast({
            component: ToastificationContent,
            props: {
              title: "تم الاستيراد بنجاح",
              icon: "WarningIcon",
              variant: "success",
            },
          });
          _g.$router.push("/teachers");
        })
        .catch(function (response) {
          _g.$toast({
            component: ToastificationContent,
            props: {
              title: "حدث خطأ",
              icon: "WarningIcon",
              variant: "danger",
            },
          });
        });
    },
  },
};
</script>

<style>
</style>
